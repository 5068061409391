import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOpeningHours, IPlant } from '../../shared/types/api_models/plant';
import { ApiService } from '../../core/services/api.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent implements OnChanges {
  @Input() plantId: number | undefined;
  plant: IPlant | undefined;
  addresses: string[] | undefined;
  openingHours: IOpeningHours | undefined;
  openingHoursBody: string[] | undefined;
  contacts: string[] | undefined;

  constructor(private apiService: ApiService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['plantId'] && this.plantId) {
      this.apiService.getPlant(this.plantId).subscribe(plant => {
        this.plant = plant;
        this.addresses = this.plant?.address.split(',');
        this.contacts = this.plant?.contact?.split(',');
        this.openingHours = this.plant?.opening_hours;
        if (this.openingHours) {
          for (let openingHour of this.openingHours.element) {
            this.openingHoursBody = openingHour.body.split(',');
          }
        }
      });
    }
  }
}
